.sloganContainer {
  display: flex;
  justify-content: space-around;
  margin-top: 65px;
}
.sloganPlusDot {
  display: flex;
  width: fit-content;
  padding: 0;
}

.slogan1 {
  font-size: 3rem;
  color: rgb(115, 147, 179);
}

.bi-dot {
  font-size: 3rem;
  margin-top: 7px;
  margin-left: -17px;
  margin-right: -26px;
  color: rgb(115, 147, 179);
}

.sloganContainer2 {
  display: flex;
  justify-content: center;
  /* margin-top: 135px; */
}

.descriptionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  line-height: 2rem;
}
.description {
  text-align: center;
  font-size: 1.7rem;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: 2px;
}

.slogan2 {
  font-size: 3rem;
  color: rgb(25, 40, 65);
  box-shadow: 6px 7px 9px 9px #b12f00;
}

.nextContact {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 35px;
  margin-top: 65px;
}

.nextContact p {
  text-align: center;
  padding-top: 17px;
  font-weight: 900;
  letter-spacing: 0.8px;
  /* align-self: center; */
}

.startup {
  display: flex;
  justify-content: center;
  font-style: italic;
  margin-top: 75px;
  margin-bottom: 75px;
}

.homeBG{
  /* background-color: blue; */
}
.geoArt{
  width: 70px;
}