.numberIcon {
  margin-right: 10px;
}

.methodIcon {
  margin-right: 10px;
  color: var(--darkBlue);
}

.about {
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 35px;
}

.method {
  font-weight: 900;
  color: var(--electricBlue);
  /* -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: var(--darkBlue); */
}

.about p {
  letter-spacing: 0.5px;
}

.about div {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.about h4{
  font-size: 1.2rem;
}
