.subscribeButton,
.subscribeNav {
  display: flex;
  justify-content: center;

  border-radius: calc(0.5rem + 0.2vw);
  /* font-family: "futuraRound"; */
  letter-spacing: 0.05rem;
  /* font-size: calc(0.4rem + 0.8vw) !important; */
  width: fit-content;
  transition: all 0.5s;
  align-items: center;
  /* box-shadow: 6px 7px 9px 9px var(--darkBlue); */
}

.subscribeNav {
  background-color: var(--buttonBlue);

  color: white;
  padding-left: 35px;
  padding-right: 35px;
  font-weight: 900;
}

.subscribeButton {
  background-color: white;
  /* border: solid black 3px; */
  border: solid var(--buttonBlue) 3px;
  color: var(--buttonBlue);
  font-weight: 900;
  height: 60px;
}

.subscribeNav:hover,
.subscribeNav:focus {
  background-color: #074cad;
  /* color: var(--buttonBlue); */
}

.subscribeButton:hover,
.subscribeButton:focus {
  /* border: none; */

  /* transform: scale(1.1); */
  box-shadow: 0;
}

.arrow {
  padding-left: 5px;
}

@media screen and (max-width: 1030px) {
  .subscribeNav {
    margin-top: 0.8rem;
    width: auto;
    /* background-color: var(--red); */
  }
  .subscribeNav,
  .subscribeButton {
    padding: calc(0.5rem + 0.1vw) calc(1.5rem + 0.5vw);
  }
}

/* .subscribeNav {
  background-color: #d470a2;
  border: solid #800080 3px;
  color: #0000ff;
  padding-left: 35px;
  padding-right: 35px;
  font-weight: 900;
}

.subscribeButton {
  background-color: #00ff00;
  border: solid var(--red) 3px;
  color: var(--red);
  font-weight: 900;
  height: 60px;
} */
