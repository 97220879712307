:root {
  --electricBlue: rgb(8, 146, 208);
  --darkBlue: #16242f;
  --navyBlue: #1d2951;
  --red: rgb(182, 6, 2);
  --lightBlue: #eaf8ff;
  --lightBluergb: 234, 248, 255;
  --buttonBlue: #085ed8;
}



.pageLayout {
  margin: 2rem 2rem 2rem 2rem;

  
}

.construction {
  text-align: center;
}

/* .constructionCont {
  justify-content: center;
} */
