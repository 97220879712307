.titleContainer {
  display: flex;
  /* flex-direction: row; */
  /* justify-content: center; */
  /* gap: 1; */
  width: 100px;

}

.orgTitle {
  font-size: 18px;
  text-wrap: wrap;
  line-height: 24px;
  font-family: "Futura";
  font-weight: 600;
  text-align: center;
  margin: auto;
  transition: all 0.5s;
  /* color: white; */
  color: var(--darkBlue);
  width: fit-content;
}

.logAltair {
  /* width: calc(1.5rem + 5vw); */
  /* width: 200px; */
  /* prevents the logo stretching vertically with display flex */
  /* align-self: center; */
  /* max-width: 74px; */
  /* border: solid red 1px; */
  margin-left: 10px;
  /* color: #1b90fa; */
  width: fit-content;
}
